@import 'assets/styles/colors';
@import url('https://fonts.googleapis.com/css?family=Amaranth&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Amaranth", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, ul {
  font-family: "Lato", sans-serif;
  color: #6c757d !important;
}

/* Customize Bootstrap-styles */

.text-light-muted {
  opacity: 0.8;
}

.dropdown-item.active {
  background-color: $color-tint-secondary;
}

.dropdown-item:active {
  background-color: $color-tint-secondary;
}

.btn-primary {
  background-color: $color-tint-primary;
  border-color: $color-tint-primary;
}

.btn-primary:hover {
  background-color: $color-tint-secondary;
  border-color: $color-tint-secondary;
}

.jumbotron {
  h1 {
    margin-bottom: 1.5rem;
  }

  p {
    @media (min-width: 768px) {
      width: 50%;
    }

    color: hsla(208, 7%, 30%, 1) !important;
    margin-bottom: 1.5rem;
  }
}

/* Colors */

.text-tint {
  color: $color-tint-primary;
}

.text-tint-secondary {
  color: $color-tint-secondary;
}

.bg-tint {
  background-color: $color-tint-primary;
}

.bg-tint-secondary {
  background-color: $color-tint-secondary;
}
