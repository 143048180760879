@import url(https://fonts.googleapis.com/css?family=Amaranth&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
.navbar {
  background-color: white; }

.navbar-brand {
  margin-right: 2rem; }

.navbar-toggler {
  border-color: transparent !important; }

.navbar-toggler:focus, .nav-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  outline: 0; }

@media (max-width: 991px) {
  .navbar-collapse {
    margin-top: 7px; } }

@media (min-width: 992px) {
  .navbar-nav {
    position: relative;
    top: -7px; } }

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */
.landing-page {
  /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
  /* Carousel base class */
  /* Since positioning the image, we need to help out the caption */
  /* Declare heights because of positioning of img element */
  /* Make Carousel fixed */
  /* CUSTOMIZE THE JUMBOTRON
  -------------------------------------------------- */
  /* Carousel base class */
  /* CUSTOMIZE THE LATEST WORK
  -------------------------------------------------- */
  /* MARKETING CONTENT
  -------------------------------------------------- */
  /* Featurettes
  ------------------------- */
  /* Thin out the marketing headings */
  /* RESPONSIVE CSS
  -------------------------------------------------- */ }
  .landing-page .carousel-caption {
    top: 0;
    left: 1rem;
    z-index: 10;
    text-align: left;
    pointer-events: none; }
  @media (min-width: 576px) {
    .landing-page .carousel-caption {
      left: 2rem; } }
  .landing-page .carousel-item {
    height: 32rem; }
  .landing-page .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
    object-fit: cover; }
  .landing-page .jumbotron {
    margin-bottom: 0; }
  .landing-page .latest-work {
    margin-bottom: 4rem; }
  .landing-page .latest-work-image-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .landing-page .latest-work-image-container .card {
      border: 0px solid; }
    .landing-page .latest-work-image-container .card-img-top {
      max-width: 100%;
      max-height: calc(100vh - 250px);
      width: auto; }
  .landing-page .marketing {
    /* Center align the text within the three columns below the carousel */ }
    .landing-page .marketing .col-lg-4 {
      margin-bottom: 1.5rem;
      text-align: center; }
    .landing-page .marketing h2 {
      font-weight: 400; }
    .landing-page .marketing .col-lg-4 p {
      margin-right: .75rem;
      margin-left: .75rem; }
    .landing-page .marketing .rounded-circle {
      width: 140px;
      height: 140px;
      object-fit: cover; }
  .landing-page .featurette-divider {
    margin: 5rem 0;
    /* Space out the Bootstrap <hr> more */ }
  .landing-page .featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem; }
  @media (min-width: 40em) {
    .landing-page {
      /* Bump up size of carousel content */ }
      .landing-page .carousel-caption p {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.4; }
      .landing-page .featurette-heading {
        font-size: 50px; } }
  @media (min-width: 62em) {
    .landing-page .featurette-heading {
      margin-top: 7rem; } }

.album .card-title {
  margin-bottom: 0.3rem; }

.album .card-text {
  font-size: 0.875rem; }

.album .react-photo-gallery--gallery > div > figure > img {
  max-height: calc(100vh - 100px); }

.album .react-photo-gallery--gallery > div > figure > div {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  background: linear-gradient(to top, #f8f9fa, 14%, rgba(248, 249, 250, 0.85), 23%, rgba(248, 249, 250, 0.7), 33%, rgba(248, 249, 250, 0.4), 45%, transparent, 70%, transparent);
  opacity: 0;
  transition: opacity 0.2s linear; }
  .album .react-photo-gallery--gallery > div > figure > div > div {
    position: absolute;
    display: -webkit-flex;
    display: flex; }

.album .react-photo-gallery--gallery > div > figure .interaction-bar figcaption {
  text-shadow: 0 1px 0 white; }

.album .react-photo-gallery--gallery > div > figure:hover > div {
  opacity: 1; }

.about-me {
  min-height: 90vh;
  background-color: #e9ecef; }
  .about-me .profile {
    min-width: 250px;
    width: 250px;
    height: 250px;
    width: 250px;
    height: 250px;
    background: white;
    border-radius: 50%;
    border-color: #fff;
    border-style: solid;
    border-width: 2px;
    -webkit-align-self: center;
            align-self: center;
    background-image: url("/content/pages/about-me/DSC03093_2.jpg");
    background-size: cover; }
  .about-me .jumbotron {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .about-me .jumbotron p {
      margin-bottom: 1.0rem; }
    @media (min-width: 768px) {
      .about-me .jumbotron {
        text-align: center;
        -webkit-align-items: center;
                align-items: center; } }
    @media (min-width: 1023px) {
      .about-me .jumbotron {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-items: stretch;
                align-items: stretch;
        min-height: 100%;
        margin-bottom: 0 !important; } }
  .about-me .text-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .about-me .text-container p {
      text-align: justify; }
    @media (min-width: 768px) {
      .about-me .text-container {
        text-align: center;
        -webkit-align-items: center;
                align-items: center; } }
    @media (min-width: 1023px) {
      .about-me .text-container p, .about-me .text-container ul {
        font-size: 0.9375rem;
        width: 450px !important;
        margin-left: 3.0rem;
        margin-right: 3.0rem; } }
    .about-me .text-container ul {
      list-style: none;
      -webkit-padding-start: 10px;
              padding-inline-start: 10px;
      margin-bottom: 1.8em; }
      .about-me .text-container ul .lead {
        font-family: 'Amaranth', sans-serif;
        font-size: 1.0em;
        line-height: 1.75em; }

.imprint {
  min-height: 90vh;
  background-color: #e9ecef; }

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Amaranth", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p, ul {
  font-family: "Lato", sans-serif;
  color: #6c757d !important; }

/* Customize Bootstrap-styles */
.text-light-muted {
  opacity: 0.8; }

.dropdown-item.active {
  background-color: #325462 !important; }

.dropdown-item:active {
  background-color: #325462 !important; }

.btn-primary {
  background-color: #5897B1 !important;
  border-color: #5897B1 !important; }

.btn-primary:hover {
  background-color: #325462 !important;
  border-color: #325462 !important; }

.jumbotron h1 {
  margin-bottom: 1.5rem; }

.jumbotron p {
  color: #474d52 !important;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .jumbotron p {
      width: 50%; } }

/* Colors */
.text-tint {
  color: #5897B1 !important; }

.text-tint-secondary {
  color: #325462 !important; }

.bg-tint {
  background-color: #5897B1 !important; }

.bg-tint-secondary {
  background-color: #325462 !important; }

