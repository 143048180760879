/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

.landing-page {

  /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */

  /* Carousel base class */
  .carousel {}

  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    top: 0;
    left: 1rem; // align with content from jumbotron that's positioned beneath the carousel
    z-index: 10;
    text-align: left;
    pointer-events: none;
  }

  @media (min-width: 576px) {
    .carousel-caption {
      left: 2rem; // align with content from jumbotron that's positioned beneath the carousel
    }
  }

  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 32rem;
  }

  .carousel-item>img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
    object-fit: cover;
  }

  /* Make Carousel fixed */
  // .landing-page-hero {
  //   position: sticky;
  //   top: 66px;
  //   width: 100%;
  //   z-index: -1;
  // }

  // .landing-page-content {}


  /* CUSTOMIZE THE JUMBOTRON
  -------------------------------------------------- */

  /* Carousel base class */
  .jumbotron {
    margin-bottom: 0;
    
  }


  /* CUSTOMIZE THE LATEST WORK
  -------------------------------------------------- */

  .latest-work {
    margin-bottom: 4rem;
  }

  .latest-work-image-container {
    display: flex;
    justify-content: flex-start;

    .card {
      border: 0px solid;
    }

    .card-img-top {
      max-width: 100%;
      max-height: calc(100vh - 250px);
      width: auto;
    }
  }


  /* MARKETING CONTENT
  -------------------------------------------------- */

  .marketing {

    /* Center align the text within the three columns below the carousel */
    .col-lg-4 {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    h2 {
      font-weight: 400;
    }

    .col-lg-4 p {
      margin-right: .75rem;
      margin-left: .75rem;
    }

    .rounded-circle {
      width: 140px;
      height: 140px;
      object-fit: cover;
    }
  }


  /* Featurettes
  ------------------------- */

  .featurette-divider {
    margin: 5rem 0;
    /* Space out the Bootstrap <hr> more */
  }

  /* Thin out the marketing headings */
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
  }


  /* RESPONSIVE CSS
  -------------------------------------------------- */

  @media (min-width: 40em) {

    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }

    .featurette-heading {
      font-size: 50px;
    }
  }

  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }
}