.album {
    .card-title {
        margin-bottom: 0.3rem;
    }
    .card-text {
        font-size: 0.875rem;
    }
    .react-photo-gallery--gallery {
        >div {
            >figure {
                >img {
                    max-height: calc(100vh - 100px);
                    // object-fit: contain;
                }
                >div {
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    flex-direction: column-reverse;
                    background: linear-gradient(
                            to top,
                            rgba(#f8f9fa, 1), 14%,
                            rgba(#f8f9fa, 0.85), 23%,
                            rgba(#f8f9fa, 0.7), 33%,
                            rgba(#f8f9fa, 0.4), 45%,
                            transparent, 70%,
                            transparent
                        );
                    opacity: 0;
                    transition: opacity 0.2s linear;

                    >div {
                        position: absolute;
                        display: flex;
                    }
                }

                .interaction-bar {
                    figcaption {
                        text-shadow: 0 1px 0 white;
                    }
                }

                &:hover >div {
                    opacity: 1;
                }

            }

            // Comment in for "zoom-in"-hover-effect
            // >figure {
            //     img {
            //         -webkit-transform: scale(1);
            //         transform: scale(1);
            //         -webkit-transition: .3s ease-in-out;
            //         transition: .3s ease-in-out;
            //     }

            //     &:hover img {
            //         -webkit-transform: scale(1.3);
            //         transform: scale(1.3);
            //     }
            // }
        }
    }
}