.navbar {
    background-color: hsl(37, 0%, 100%);
}

.navbar-brand {
    margin-right: 2rem;
}

.navbar-toggler {
    border-color: transparent !important;
}

.navbar-toggler, .nav-link {
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
        outline: 0;
    }
}

@media (max-width: 991px) {
    .navbar-collapse {
        margin-top: 7px;
    }
}

@media (min-width: 992px) {
    .navbar-nav {
        position: relative;
        top: -7px;
    }
}
