.about-me {
    min-height: 90vh;
    background-color: #e9ecef;

    .profile {
        min-width: 250px;
        width: 250px;
        height: 250px;
        width: 250px;
        height: 250px;
        background: white;
        border-radius: 50%;
        border-color: #fff;
        border-style: solid;
        border-width: 2px;
        align-self: center;
        background-image: url('/content/pages/about-me/DSC03093_2.jpg');
        background-size: cover;
    }

    .jumbotron {
        display: flex;
        flex-direction: column;

        p {
            margin-bottom: 1.0rem;
        }

        @media (min-width: 768px) {
            text-align: center;
            align-items: center;
        }

        @media (min-width: 1023px) {
            flex-direction: row;
            justify-content: space-around;
            align-items: stretch;
            min-height: 100%;
            margin-bottom: 0 !important;
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;

        p {
            text-align: justify;
        }

        @media (min-width: 768px) {
            text-align: center;
            align-items: center;
        }

        @media (min-width: 1023px) {
            p, ul {
                font-size: 0.9375rem;
                width: 450px !important;
                margin-left: 3.0rem;
                margin-right: 3.0rem;
            }
        }

        ul {
            list-style: none;
            padding-inline-start: 10px;
            margin-bottom: 1.8em;

            .lead {
                font-family: 'Amaranth', sans-serif;
                font-size: 1.0em;
                line-height: 1.75em;
            }
        }
    }
}
